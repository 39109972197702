import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, POST } from "@/core/services/store/request.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "user-list",
  data: function data() {
    return {
      exportLoading: false,
      pageModule: "user-listing",
      routeAPI: "user",
      routeName: "user",
      routeDetailName: "user.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],
      moreActions: [
      /*{
        title: "Import User(s)",
        action: "import_items",
        divider: true,
        icon: "mdi-database-import",
      },
      {
        title: "Export User(s)",
        action: "export_items",
        divider: false,
        icon: "mdi-database-export",
      },*/

      /*{
        title: "Export Current View",
        action: "export_current_view",
        divider: true,
        icon: "mdi-database-export",
      },*/
      {
        title: "Refresh List",
        action: "refresh_list",
        divider: true,
        icon: "mdi-refresh"
      }],
      userMoreAction: [{
        title: "Mark as Active",
        icon: "mdi-check-all",
        action: "active"
      }, {
        title: "Mark as In-Active",
        icon: "mdi-check-all",
        action: "inactive"
      }]
    };
  },
  components: {
    draggable: draggable,
    TableActivity: TableActivity,
    ListingTemplate: ListingTemplate,
    ListingFooter: ListingFooter,
    ListingTable: ListingTable,
    ListingHeader: ListingHeader,
    ListingSearchCriteria: ListingSearchCriteria
  },
  methods: {
    getProfileImage: function getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }

      return null;
    },
    getBillingAddress: function getBillingAddress(billing) {
      var billingArray = new Array();

      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }

        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }

        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }

        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }

      return billingArray.join(", ");
    },
    moreAction: function moreAction(action) {
      var _this = this;

      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;

        case "export_items":
          _this.bulkUserExport();

          break;

        case "export_current_view":
          break;

        case "refresh_list":
          _this.getRows();

          break;
      }
    },
    updateMoreAction: function updateMoreAction(param) {
      var _this = this;

      switch (param) {
        case "active":
          _this.bulkUserUpdate({
            users: _this.selectedRows,
            status: 1
          });

          break;

        case "inactive":
          _this.bulkUserUpdate({
            users: _this.selectedRows,
            status: 0
          });

          break;
      }
    },
    handleFileUpload: function handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows: function importRows() {
      // const _this = this;
      var file = this.$refs.file.files[0];

      if (!file) {
        alert("No file chosen");
        return;
      }

      var data = new FormData();
      data.append("excel", file);

      var _this = this;

      _this.$store.dispatch(POST, {
        url: "user/import",
        data: data
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.selectedRows = [];

        _this.getRows();

        _this.importDialog = false;
      });
    },
    bulkUserUpdate: function bulkUserUpdate(requestObject) {
      var _this = this;

      _this.$store.dispatch(PATCH, {
        url: "user",
        data: requestObject
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.selectedRows = [];

        _this.getRows();
      });
    }
  },
  mounted: function mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "User"
    }]);
  },
  beforeMount: function beforeMount() {
    var _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [{
      headerName: "",
      field: "id",
      sort: null,
      visible: true,
      fixed: true,
      sortable: false,
      checkbox: true,
      order: 1
    }, {
      headerName: "Image",
      field: "image",
      sort: null,
      image: true,
      visible: true,
      fixed: false,
      sortable: false,
      order: 2
    }, {
      headerName: "User Info",
      field: "user_name",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 4
    }, {
      headerName: "Role",
      field: "role",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 5
    }, {
      headerName: "Created Time",
      child: "created_at",
      field: "added_at",
      sort: null,
      visible: true,
      fixed: false,
      sortable: false,
      order: 6
    }, {
      headerName: "Last Modified Time",
      child: "modified_at",
      field: "updated_at",
      sort: null,
      visible: false,
      fixed: false,
      sortable: false,
      order: 7
    }];

    var defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true
    });

    _this.defaultColShow = defaultColDefs.map(function (col) {
      return col.field;
    });
    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize = window.localStorage.getItem(_this.pageModule) || 10;
  }
};